<template>
  <div class="py-3 min-w-0 w-full">
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Ladataan vuokrasopimusta..."
      class="full-page-loader"
    ></full-page-loader>

    <div v-else>
      <div class="display-1 d-flex">
        <span class="display-1">Vuokrasopimus # {{ contract.contractNumber }}</span>

        <v-spacer></v-spacer>
        <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
      </div>

      <v-card class="mt-2">
        <v-card-title :class="getContractStateColor(contract.state)">
          {{ getContractState(contract.state) }}</v-card-title
        >
        <v-container fluid>
          <v-row dense>
            <v-col>
              <!-- TABS -->
              <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab v-for="item in tabs" :key="item">
                  <div>{{ item }}</div>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>

        <!-- Notes -->
        <rental-contract-notes v-if="tab == 0"></rental-contract-notes>

        <!--  OTHER TENANTS -->
        <key-holders v-if="tab == 1"></key-holders>

        <!--  REPORTS -->
        <contract-reports v-if="tab == 2"></contract-reports>
      </v-card>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
import KeyHolders from "@/components/RentalContract/ContractOverview/KeyHolders";
import ContractReports from "@/components/RentalContract/ContractOverview/ContractReports";
import RentalContractNotes from "@/components/RentalContract/ContractOverview/RentalContractNotes";
import FullPageLoader from "@/components/FullPageLoader.vue";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  title: "Vuokrasopimuksen tiedot",

  components: { RentalContractNotes, KeyHolders, ContractReports, FullPageLoader },

  mixins: [mixins],

  data() {
    return {
      tab: 0,
      tabs: ["Muistiinpanot", "Avaimenhaltijat", "Raportit"],
      contractHTML: "",
    };
  },

  computed: {
    ...mapState("contract", ["contract", "loading"]),
  },

  async created() {
    try {
      this.setLoading(true);
      await this.getFormattedContract(this.$route.params.id);
      this.setLoading(false);
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapActions("contract", ["getFormattedContract"]),
    ...mapMutations("contract", ["setLoading"]),
  },
};
</script>

<style></style>
