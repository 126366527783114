<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text class="mt-2">
        <v-form ref="form">
          <v-text-field
            label="Nimi"
            v-model="tenant.name"
            :rules="validations.required"
            outlined
            dense
          ></v-text-field>

          <v-text-field label="Puhelin" v-model="tenant.phone" outlined dense></v-text-field>

          <v-text-field
            label="Email"
            v-model.trim="tenant.email"
            :rules="validations.email2"
            outlined
            dense
          ></v-text-field>

          <v-text-field label="Osoite" v-model="tenant.address" outlined dense></v-text-field>

          <v-text-field label="Postinumero" v-model="tenant.zipCode" outlined dense></v-text-field>

          <v-text-field label="Kaupunki" v-model="tenant.city" outlined dense></v-text-field>

          <v-textarea
            v-model="tenant.comments"
            placeholder="Muuta tietoa vuokralaisesta/avaimenhaltijasta..."
            label="Kommentit"
            outlined
            dense
          ></v-textarea>

          <div v-if="!updatingContractTenants">
            <label>Hae vuokrasopimuksen numerolla tai vuokralaisen nimellä.</label>
            <v-autocomplete
              class="mt-1"
              v-model="tenant.relatedContracts"
              :items="activeRentalContracts"
              :item-text="nameContractnumber"
              item-value="_id"
              label="Liitetty vuokrasopimukseen"
              no-data-text="Vuokrasopimuksen numero tai vuokralainen"
              outlined
              multiple
              small-chips
              return-object
              deletable-chips
              :search-input.sync="searchInput"
              @change="searchInput = ''"
              @input.native="getRentalContract"
            >
            </v-autocomplete>
          </div>

          <div v-if="edit">
            <p class="mb-1">Muuta vuokralaisen tyyppiä</p>
            <v-select
              v-model="tenant.selectedTenantType"
              label="Vuokralaisen tyyppi"
              outlined
              dense
              item-value="val"
              item-text="text"
              :items="[
                { text: 'Avaimenhaltija', val: 'keyHolder' },
                { text: 'Vuokralainen', val: 'tenant' },
              ]"
            ></v-select>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click.prevent="submit()">{{ btnText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { debounce } from "../../utils/helpers";
import { mapActions, mapState } from "vuex";
import validations from "@/validations";

export default {
  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    updatingContractTenants: { type: Boolean, default: false },
    isRemovedTenant: { type: Boolean, default: false },
    select: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  mixins: [mixins],

  data() {
    return {
      editVal: false,
      searchInput: "",
      contractInput: "",
      tenant: {
        name: "",
        phone: "",
        email: "",
        address: "",
        zipCode: "",
        city: "",
        comments: "",
        isOnlyKeyHolder: true,
        contract: [],
        relatedContracts: [],
        removedRelatedContracts: [],
        selectedTenantType: "keyHolder",
      },

      validations,
    };
  },

  computed: {
    ...mapState("keyholder", ["keyHolder"]),
    ...mapState("contract", ["activeRentalContracts"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    btnText() {
      if (this.edit) {
        return "Tallenna";
      } else {
        return "Luo";
      }
    },

    title() {
      if (this.edit) {
        return "Muokkaa avaimenhaltijaa";
      } else {
        return "Luo avaimenhaltija";
      }
    },
  },

  watch: {
    keyHolder: {
      deep: true,
      handler(val) {
        this.$store.state.contract.activeRentalContracts = [...val.relatedContracts];
        this.tenant = { ...val };
        if (val.isOnlyKeyHolder) {
          this.tenant.selectedTenantType = "keyHolder";
        } else {
          this.tenant.selectedTenantType = "tenant";
        }
      },
    },

    contractInput: debounce(function (newVal) {
      this.searchContract(`/api/v1/rental-contract/search-rental-contract?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("keyholder", ["addKeyHolder", "updateKeyHolder"]),
    ...mapActions("contract", ["searchContract"]),

    getRentalContract(event) {
      this.contractInput = event.target.value;
    },

    nameContractnumber(item) {
      if (item.tenant) {
        return item.contractNumber + ", " + item.tenant.name;
      }
    },

    async submit() {
      if (this.$refs.form.validate()) {
        let tenant = { ...this.tenant };

        tenant.relatedContracts = tenant.relatedContracts.map((el) => {
          return el._id;
        });

        tenant.removedRelatedContracts = tenant.removedRelatedContracts.map((el) => {
          return el._id;
        });

        if (this.edit) {
          this.updateKeyHolder({
            keyHolder: tenant,
            updatingContractTenants: this.updatingContractTenants,
            isRemovedTenant: this.isRemovedTenant,
          });
        } else {
          // Create
          await this.addKeyHolder({
            ...tenant,
            isOnlyKeyHolder: true,
          });

          this.$refs.form.reset();
        }
      }
    },
  },
};
</script>

<style scoped></style>
