<template>
  <!-- Dialog Adding key -->

  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title> Lisää avaimenhaltija </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="tenants"
                :items="allTenants"
                item-text="name"
                item-value="_id"
                label="Vuokralaiset ja avaimenhaltijat"
                multiple
                outlined
                small-chips
                deletable-chips
                :search-input.sync="searchInput"
                @change="searchInput = ''"
                @focus="getTenants()"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="addTenants()">Lisää</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosMethods from "@/mixins/axios";
import mixins from "@/mixins/mixins";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      searchInput: "",
      allTenants: [],
      tenants: [],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    async getTenants() {
      try {
        const res = await axiosMethods.get("/api/v1/tenant/getAll/");
        this.allTenants = res.data.tenants;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    addTenants() {
      if (this.tenants.length < 1) {
        return this.showPopup("Valitse ainakin yksi vuokralainen tai avaimenhaltija", "error");
      }

      this.$emit("addadditionaltenants", [...this.tenants]);
      this.tenants = [];
    },
  },
};
</script>

<style scoped></style>
