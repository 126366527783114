<template>
  <v-container>
    <v-card flat class="mt-2">
      <v-card-text>
        <strong>Sopimus alkaa: {{ formatDate(contract.lease.startDate) }}</strong> <br />
        <strong
          >Sopimus päättyy:
          {{
            contract.lease.endDate
              ? formatDate(contract.lease.endDate)
              : contract.revokedDate
              ? formatDate(contract.revokedDate)
              : "Sopimus on toistaiseksi voimassa"
          }}</strong
        >
      </v-card-text>
      <v-card-text>
        <v-form ref="fullReportForm">
          <v-row dense>
            <v-col cols="12" sm="6" md="4" xl="2">
              <v-menu
                ref="startMenu"
                v-model="startMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDateFormatted"
                    outlined
                    dense
                    label="Alkaen"
                    v-bind="attrs"
                    :rules="required"
                    v-on="on"
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates.startDate"
                  first-day-of-week="1"
                  @input="startMenu = false"
                  @change="hideResults = true"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="2">
              <v-menu
                ref="endMenu"
                v-model="endMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDateFormatted"
                    outlined
                    dense
                    label="Päättyen"
                    v-bind="attrs"
                    :rules="required"
                    v-on="on"
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates.endDate"
                  first-day-of-week="1"
                  @input="endMenu = false"
                  @change="hideResults = true"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>

        <v-row dense>
          <v-col md="4">
            <v-radio-group
              v-model="searchType"
              :mandatory="true"
              style="margin: 0; padding: 0"
              @change="
                $store.state.contract.contractReportData = [];
                hideResults = true;
              "
            >
              <v-radio label="Laskut maksusuoritusten mukaan" value="payment"></v-radio>
              <v-radio label="Laskut eräpäivän mukaan" value="dueDate"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col md="4">
            <v-btn
              color="info"
              @click="
                getReport();
                hideResults = false;
              "
              >Hae laskutustiedot</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- RESULTS ROW -->
    <v-row v-if="!hideResults" dense>
      <v-col md="4">
        <!-- Searching invoices... -->
        <v-card v-if="!resultsArrived && searching">
          <v-card-text>
            <h3>Etsitään laskuja...</h3>
          </v-card-text>
        </v-card>

        <!-- Invoice results DueDate -->
        <v-card
          v-if="resultsArrived && contractReportData.length > 0 && searchType == 'dueDate'"
          class="resultsBox"
        >
          <!-- InvoiceData -->
          <v-card-text style="padding-bottom: 0; margin-bottom: 20px">
            <h3 class="mb-1">Laskutustiedot</h3>
            <p>Laskuja yhteensä: {{ contractReportData.length }} kpl</p>
            <p>
              Saatavat yhteensä: {{ formatCurrency(calcTotalSum(contractReportData)) }} (sis. alv)
            </p>
            <p>
              Avoinna yhteensä: {{ formatCurrency(calcOpenAmountTotal(contractReportData)) }} (sis.
              alv)
            </p>
          </v-card-text>

          <!-- Searching products -->
          <v-card-text
            v-if="productSearching && invoiceProducts.length == 0 && searchType == 'dueDate'"
          >
            <h3>Etsitään tuotekohtaisia laskutussummia...</h3>
          </v-card-text>

          <!-- Products -->
          <v-card-text
            v-if="!productSearching && invoiceProducts.length > 0 && searchType == 'dueDate'"
            style="margin-top: -20px"
          >
            <h3 class="mb-1">Tuotekohtaiset laskutussummat</h3>
            <div v-for="(product, index) in invoiceProducts" :key="'a' + index">
              <p>
                {{ formatProductName(product.desc) }} yhteensä
                {{ formatCurrency(countProductTotal(contractReportData, product._id)) }} (sis. alv)
              </p>
            </div>
          </v-card-text>

          <!-- No Products -->
          <v-card-text
            v-if="!productSearching && invoiceProducts.length == 0 && searchType == 'dueDate'"
            style="margin-top: -20px"
          >
            <h3>Tuotekohtaisia tietoja ei saatavilla...</h3>
          </v-card-text>
        </v-card>

        <!-- Invoice results Payments -->
        <v-card
          v-if="resultsArrived && contractReportData.length > 0 && searchType == 'payment'"
          class="resultsBox"
        >
          <v-card-text style="padding-bottom: 0; margin-bottom: 20px">
            <h3 class="mb-1">Laskutustiedot</h3>
            <p>
              Toteutuneet suoritukset yhteensä:
              {{
                formatCurrency(
                  calcTotalPaid(contractReportData) - calcTotalCreditLoss(contractReportData)
                )
              }}
              (sis. alv)
            </p>
          </v-card-text>
        </v-card>

        <!-- No results -->
        <v-card v-if="resultsArrived && contractReportData.length == 0" class="resultsBoxError">
          <v-card-text>
            <p>Laskuja ei löytynyt tällä aikavälillä.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "../../../mixins/mixins";
import { mapState, mapActions } from "vuex";

export default {
  title: "Raportit",

  mixins: [mixins],

  data() {
    return {
      resultsArrived: false,
      searching: false,
      productSearching: false,
      startMenu: false,
      endMenu: false,
      hideResults: false,
      contractStartDate: "",
      contractEndDate: "",
      searchType: "payment",
      dates: {
        startDate: "",
        endDate: "",
      },
      name: "raportti",
      startDateFormatted: "",
      endDateFormatted: "",
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  watch: {
    "dates.startDate"() {
      this.startDateFormatted = this.formatDate(this.dates.startDate);
    },

    "dates.endDate"() {
      this.endDateFormatted = this.formatDate(this.dates.endDate);
    },

    contractReportData(invoices) {
      this.getValidProduct(invoices);
    },
  },

  computed: {
    ...mapState("contract", ["contract", "contractReportData", "invoiceProducts"]),
  },

  methods: {
    ...mapActions("contract", ["getContractReport", "getProductsById"]),

    async getReport() {
      this.resultsArrived = false;
      this.searching = true;
      this.$store.state.contract.invoiceProducts = [];

      if (this.$refs.fullReportForm.validate()) {
        if (new Date(this.dates.startDate).getTime() > new Date(this.dates.endDate).getTime()) {
          return this.showPopup("Aloituspäivä ei voi olla suurempi kuin päättymispäivä", "error");
        }

        // Get Contract report data (invoices)
        await this.getContractReport({
          dates: this.dates,
          contractId: this.$route.params.id,
          searchType: this.searchType,
        });

        this.resultsArrived = true;
        this.searching = false;
      }
    },

    async getValidProduct(invoices) {
      this.productSearching = true;
      let ids = [];

      invoices.forEach((invoice) => {
        // Normal products
        invoice.products.forEach((product) => {
          if (product.productId) {
            const found = ids.includes(product.productId);
            if (!found) ids.push(product.productId);
          }
        });

        // Updated products
        if (invoice.updatedProducts.length > 0) {
          invoice.updatedProducts.forEach((product) => {
            if (product.productId) {
              const found = ids.includes(product.productId);
              if (!found) ids.push(product.productId);
            }
          });
        }
      });

      // Get Products
      if (ids.length > 0) await this.getProductsById(ids);
      this.productSearching = false;
    },

    calcTotalSum(invoices) {
      let total = 0;
      invoices.forEach((el) => {
        total += el.totalAmount;
      });

      return total;
    },

    countProductTotal(invoices, productId) {
      let total = 0;

      invoices.forEach((invoice) => {
        invoice.products.forEach((product) => {
          if (String(product.productId) == String(productId)) {
            total += product.amount * product.count * (1 + product.taxpr / 100);
          }
        });
        // }
      });

      return total;
    },

    calcTotalCreditLoss(invoices) {
      let total = 0;

      invoices.forEach((invoice) => {
        invoice.comments.forEach((comment) => {
          if (
            comment.text == "5" &&
            new Date(comment.date).getTime() >= new Date(this.dates.startDate).getTime() &&
            new Date(comment.date).getTime() <= new Date(this.dates.endDate).getTime()
          ) {
            total += comment.amount;
          }
        });
      });

      return total;
    },

    calcOpenAmountTotal(invoices) {
      let total = 0;
      invoices.forEach((el) => {
        total += el.openAmount;
      });

      return total;
    },

    calcTotalPaid(invoices) {
      let total = 0;

      invoices.forEach((invoice) => {
        invoice.paidAmounts.forEach((el) => {
          if (
            new Date(el.date).getTime() >= new Date(this.dates.startDate).getTime() &&
            new Date(el.date).getTime() <= new Date(this.dates.endDate).getTime()
          ) {
            total += el.amount;
          }
        });
      });

      return total;
    },

    formatProductName(name) {
      return name.includes("{{") && name.includes("}}") ? name.split("{{")[0] : name;
    },
  },
};
</script>

<style scoped>
.v-application p {
  font-size: 16px;
}

.resultsBox {
  border: 1px solid var(--v-success-base);
}

.resultsBoxError {
  border: 1px solid var(--v-error-base);
}
</style>
