<template>
  <v-container>
    <div class="text-right">
      <v-btn color="primary" class="mb-2 ml-auto" @click="addAdditionalTenantForm = true"
        >Lisää uusi</v-btn
      >
    </div>

    <!-- Colorcodes -->
    <div>
      <p>
        <v-icon class="mr-1" :color="'green'" small>mdi-key-variant</v-icon>Avaimet käyvät
        vuokrasopimuksen vuokrakohteeseen ja taloyhtiöön
      </p>
      <p>
        <v-icon class="mr-1" :color="'yellow'" small>mdi-key-variant</v-icon>Avaimet käyvät
        vuokrasopimuksen taloyhtiöön
      </p>
      <p>
        <v-icon class="mr-1" :color="'red'" small>mdi-key-variant</v-icon>Avaimet eivät käy
        vuokrasopimuksen taloyhtiöön eivätkä vuokrakohteeseen
      </p>
    </div>

    <!-- Tenants -->

    <div v-if="additionalTenants.length < 1" justify="center">
      <h3 class="text-center mb-5 mt-5">Ei aktiivisia avaimenhaltijoita</h3>
    </div>

    <div v-if="additionalTenants.length > 0" justify="center">
      <h3 class="mb-1 mt-5">Aktiiviset avainhaltijat</h3>
    </div>
    <v-row class="mt-2" v-if="!isLoading" dense>
      <v-col
        v-for="(tenant, idx) in additionalTenants"
        :key="idx + 'a'"
        cols="12"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card height="100%" class="d-flex flex-column">
          <div>
            <v-list-item three-line>
              <v-list-item-content>
                <!-- {{ tenant.keys }} -->
                <div class="overline mb-3">Asiakasnro. # {{ tenant.clientId }}</div>
                <router-link style="text-decoration: none" :to="`/tenant/overview/${tenant._id}`">
                  <v-list-item-title class="headline mb-2"> {{ tenant.name }} </v-list-item-title>
                </router-link>
                <!-- Keys -->
                <div v-if="tenant.keys.length > 0">
                  <div v-for="(key, idx) in tenant.keys" :key="idx + 'b'">
                    <p class="font-weight-bold" style="margin-bottom: 8px; margin-top: 5px">
                      <v-icon :color="checkRights(key)" small>mdi-key-variant</v-icon>
                      {{ key.code }}
                    </p>

                    <!-- Condominiums -->
                    <p v-if="key.condominiums.length < 1" style="color: red">
                      Ei taloyhtiöoikeuksia
                    </p>

                    <div v-for="(cond, idx) in key.condominiums" :key="idx + 'd'">
                      <div style="margin-bottom: 5px; margin-top: 5px">{{ cond.name }}</div>

                      <p
                        v-for="(apart, idx) in getMatchingApartments(cond, key.apartments)"
                        :key="idx + 'c'"
                        style="margin-bottom: 1px"
                      >
                        <span v-if="cond._id == apart.condominium._id">
                          <span>{{ apart.address }}</span
                          ><span>{{
                            apart.apartmentNumber ? `, ${apart.apartmentNumber}` : ""
                          }}</span></span
                        >
                      </p>
                    </div>

                    <!-- Apartments -->
                    <p v-if="key.apartments.length < 1" style="color: red; margin-bottom: 6px">
                      Ei yksittäisiä vuokrakohdeoikeuksia
                    </p>

                    <v-divider
                      v-if="idx < tenant.keys.length - 1"
                      style="margin-top: 10px; margin-bottom: 7px"
                    ></v-divider>
                  </div>
                </div>
                <span v-else style="color: red">Ei avaimia</span>
              </v-list-item-content>
              <v-list-item-avatar size="35">
                <v-icon medium>mdi-account</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </div>

          <v-card-actions class="mt-auto">
            <v-btn
              outlined
              small
              color="error"
              @click="
                selected = { ...tenant };
                removeFrom = 'relatedContracts';
                deleteDialog = true;
              "
            >
              Poista
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              small
              color="info"
              @click="
                isRemovedTenant = false;
                editItem(tenant);
              "
            >
              Muokkaa
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="mt-5"></v-divider>

    <!-- Removed tenants -->

    <div v-if="removedTenants.length > 0" justify="center">
      <h3 class="mb-1 mt-5">Poistetut avainhaltijat</h3>
    </div>

    <v-row class="mt-2 removed-container" v-if="!isLoading" dense>
      <v-col
        v-for="(tenant, idx) in removedTenants"
        :key="idx + 'a'"
        cols="12"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card height="100%" class="d-flex flex-column removed-card-container">
          <div>
            <v-list-item three-line>
              <v-list-item-content>
                <!-- {{ tenant.keys }} -->
                <div class="overline mb-3">Asiakasnro. # {{ tenant.clientId }}</div>
                <router-link style="text-decoration: none" :to="`/tenant/overview/${tenant._id}`">
                  <v-list-item-title class="headline mb-2"> {{ tenant.name }} </v-list-item-title>
                </router-link>
                <!-- Keys -->
                <div v-if="tenant.keys.length > 0">
                  <div v-for="(key, idx) in tenant.keys" :key="idx + 'b'">
                    <p class="font-weight-bold" style="margin-bottom: 8px; margin-top: 5px">
                      <v-icon :color="checkRights(key)" small>mdi-key-variant</v-icon>
                      {{ key.code }}
                    </p>

                    <!-- Condominiums -->
                    <p v-if="key.condominiums.length < 1" style="color: red">
                      Ei taloyhtiöoikeuksia
                    </p>

                    <div v-for="(cond, idx) in key.condominiums" :key="idx + 'd'">
                      <div style="margin-bottom: 5px; margin-top: 5px">{{ cond.name }}</div>

                      <p
                        v-for="(apart, idx) in getMatchingApartments(cond, key.apartments)"
                        :key="idx + 'c'"
                        style="margin-bottom: 1px"
                      >
                        <span v-if="cond._id == apart.condominium._id">
                          <span>{{ apart.address }}</span
                          ><span>{{
                            apart.apartmentNumber ? `, ${apart.apartmentNumber}` : ""
                          }}</span></span
                        >
                      </p>
                    </div>

                    <!-- Apartments -->
                    <p v-if="key.apartments.length < 1" style="color: red; margin-bottom: 6px">
                      Ei yksittäisiä vuokrakohdeoikeuksia
                    </p>

                    <v-divider
                      v-if="idx < tenant.keys.length - 1"
                      style="margin-top: 10px; margin-bottom: 7px"
                    ></v-divider>
                  </div>
                </div>
                <span v-else style="color: red">Ei avaimia</span>
              </v-list-item-content>
              <v-list-item-avatar size="35">
                <v-icon medium>mdi-account</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </div>

          <v-card-actions class="mt-auto">
            <v-btn
              outlined
              small
              color="error"
              @click="
                selected = { ...tenant };
                removeFrom = 'removedRelatedContracts';
                deleteDialog = true;
              "
            >
              Poista
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              small
              color="info"
              @click="
                isRemovedTenant = true;
                editItem(tenant);
              "
            >
              Muokkaa
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Delete dialog -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title>Poista avaimenhaltija sopimukselta</v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12">
                <v-radio-group v-model="deleteType" :mandatory="true" style="margin-top: 0px">
                  <v-radio label="Poista kokonaan sopimukselta" value="delete"></v-radio>
                  <v-radio
                    v-if="removeFrom === 'relatedContracts'"
                    label="Siirrä poistettuihin"
                    value="move"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="deleteDialog = false">Poistu</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="
              removeTenant(selected._id);
              deleteDialog = false;
            "
            >Tallenna</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add tenant Key -->
    <add-key-holders
      v-model="addAdditionalTenantForm"
      @addadditionaltenants="addTenants"
    ></add-key-holders>

    <!-- Edit Tenant -->
    <key-holder
      v-model="keyHolderDialog"
      :edit="true"
      :updatingContractTenants="true"
      :isRemovedTenant="isRemovedTenant"
    ></key-holder>
  </v-container>
</template>

<script>
import mixins from "@/mixins/mixins";
import AddKeyHolders from "@/components/RentalContract/ContractOverview/AddKeyHolders";
import KeyHolder from "@/components/Keys/KeyHolder";
import { mapState, mapActions } from "vuex";

export default {
  title: "Hallitse vuokralaisia",

  components: {
    AddKeyHolders,
    KeyHolder,
  },

  mixins: [mixins],

  async created() {
    this.isLoading = true;
    await this.getAdditionalTenants(this.$route.params.id);
    this.isLoading = false;
  },

  data: () => ({
    isRemovedTenant: false,
    selected: {},
    addAdditionalTenantForm: false,
    tenants: [],
    isLoading: true,
    keyHolderDialog: false,
    deleteDialog: false,
    deleteType: "",
    removeFrom: "",
  }),

  computed: {
    ...mapState("keyholder", ["additionalTenants", "removedTenants"]),
    ...mapState("contract", ["contract"]),
  },

  methods: {
    ...mapActions("keyholder", [
      "getAdditionalTenants",
      "removeAdditionalTenant",
      "addAdditionalTenants",
    ]),

    editItem(tenant) {
      if (tenant.isOnlyKeyHolder) {
        this.$store.state.keyholder.keyHolder = { ...tenant };
        this.keyHolderDialog = true;
      } else {
        this.$router.push("/tenant/edit/" + tenant._id);
      }
    },

    async addTenants(tenants) {
      await this.addAdditionalTenants({ tenants, contractId: this.$route.params.id });
      await this.getAdditionalTenants(this.$route.params.id);
    },

    async removeTenant(id) {
      await this.removeAdditionalTenant({
        tenantId: id,
        deleteType: this.deleteType,
        removeFrom: this.removeFrom,
        contractId: this.$route.params.id,
      });
      await this.getAdditionalTenants(this.$route.params.id);
    },

    checkRights(key) {
      const condominiumIndex = key.condominiums.findIndex(
        (condominium) => condominium._id == this.contract.apartment.id.condominium._id
      );
      const apartmentIndex = key.apartments.findIndex(
        (apartment) => apartment._id == this.contract.apartment.id._id
      );

      if (condominiumIndex !== -1 && apartmentIndex === -1) {
        return "warning";
      }

      if (condominiumIndex != -1 && apartmentIndex != -1) {
        return "green";
      } else return "grey";
    },

    getMatchingApartments(condominium, apartments) {
      return apartments.filter((apartment) => apartment.condominium._id == condominium._id);
    },
  },
};
</script>

<style scoped>
.v-list-item__title {
  font-weight: 600;
  font-size: 18px !important;
}

p {
  color: rgb(139, 139, 139);
  font-size: 13px !important;
}

.removed-container {
  opacity: 0.8;
}

.removed-card-container {
  border: 1px solid rgb(248, 139, 139);
}

@media (max-width: 768px) {
  p {
    font-size: 11px !important;
  }
}
</style>
